import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormBuilder, FormGroup  } from '@angular/forms';
import { SessionService } from '../../../services/session.service'
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import templateString from './signup.component.html';
import styleString from './signup.component.scss';
import { CookieService } from 'ngx-cookie-service';
import { SubfoldingLinkService } from '../../../services/subfolding-link.service';
import { CookiesResetService } from '../../../services/cookies-reset.service';
import CustomAlert from '../../../models/custom-alert.model';


@Component({
  selector: 'app-signup',
  template: templateString,
  styles: [ styleString ],
  providers:  [ SessionService, UserService, SubfoldingLinkService ]
})
export class SignupComponent implements OnInit {

  @Input() refresh:boolean;
  signup: FormGroup;
  submitted:boolean;
  storeName = ''

  constructor(private window: Window,
    private sessionService: SessionService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,
    public subfoldingLinkService: SubfoldingLinkService,
    public cookiesResetService: CookiesResetService
  ){
    if(window["data"])
    {this.storeName = window["data"]["storeName"]}
  }

  ngOnInit() {
    this.signup = this.formBuilder.group({
      email:['', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/)]],
      password:['',[Validators.required, Validators.minLength(6)]],
      news_letter: false,
      enabled_marketing: false
    });
  }

  get f() { return this.signup.controls; }

  onSubmit() {
    var email = (this.signup.get('email').value).toLowerCase();
    var password = this.signup.get('password').value;
    var news_letter = this.signup.get('news_letter').value;
    var enabled_marketing = this.signup.get('enabled_marketing').value;

    this.submitted = true;
    // stop here if form is invalid
    if (this.signup.invalid) {
      return;
    }else{
      let user = new User(email,password, news_letter, enabled_marketing);
      this.sessionService.signUp(user).subscribe((data) => {
        this.sessionService.obtainAccessToken({username: email, password: password}).subscribe((data) => {
          const onUserCreatedSuccessfully = (self)=>{
            self.sessionService.deleteGuestEmail();
            var tokenData = data;
            self.sessionService.saveToken(tokenData);
            self.userService.mergeCart().subscribe((token) => {
                var orderToken = token["token"];
                // self.cookiesResetService.setItem('order_token', JSON.stringify(orderToken));
                self.cookiesResetService.set("order_token", orderToken);
                if (self.refresh){
                  self.window.location.href = self.subfoldingLinkService.linkWithSubdomainOrNot("/cart");
                }
              })

            if (self.refresh == false){
              self.window.location.href = self.subfoldingLinkService.linkWithSubdomainOrNot("/user/account");
            }
          }
          CustomAlert.confirm('USER_CREATED',{},{
            showYesActionBtn:true,
            yesActionText: 'OK',
            yesActionCallback: onUserCreatedSuccessfully,
            self: this
          });
        });
      },
      (error: any) => {
        var errorBody = error.error;
        CustomAlert.show('ERROR',{ error: errorBody.error });
      }
    );
    }
  }


}
