import { Component, OnInit, Input, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup  } from '@angular/forms';
import { SessionService } from '../../../services/session.service';
import { SubscriptionService } from "../../../services/subscription.service";
import templateString from './guest-checkout.component.html';
import styleString from './guest-checkout.component.scss';
import { SubfoldingLinkService } from '../../../services/subfolding-link.service';

@Component({
  selector: 'app-guest-checkout',
  template: templateString,
  styles: [ styleString ],
  providers:  [ SessionService, SubscriptionService, SubfoldingLinkService ]
})
export class GuestCheckoutComponent implements OnInit {

  @Input() refresh:boolean = false;
  @Input() redirect:boolean;
  guestCheckout: FormGroup;
  submitted:boolean

  constructor(public window: Window,
              public sessionService: SessionService,
              public formBuilder: FormBuilder,
              public subscriptionService: SubscriptionService,
    public subfoldingLinkService: SubfoldingLinkService
  ){}

  ngOnInit() {
    this.guestCheckout = this.formBuilder.group({
      email:['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,15}$")]],
      news_letter: false
    });
  }

  get f() { return this.guestCheckout.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.guestCheckout.invalid) return;
    else {
      let email = (this.guestCheckout.get('email').value).toLowerCase();
      let email_subscribed = this.guestCheckout.get('news_letter').value;
      let redirectTo = '/cart';
      if(window['data']['checkoutFlow']=="v3" && !this.refresh) redirectTo = '/checkout';
      this.sessionService.saveGuestEmail(email);
      
      if(email_subscribed){
        this.subscriptionService.subscribeMailchimp(email, false, email_subscribed).subscribe((subscription) => {
          subscription = subscription['body'];
          console.log(subscription['message']);
        }, (err) => { console.log("error: " + err['statusText']); },
        () => { window.location.href = this.subfoldingLinkService.linkWithSubdomainOrNot('/cart'); } );
      } else window.location.href = this.subfoldingLinkService.linkWithSubdomainOrNot(redirectTo);
    }
  }
}
