import { Component, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { ConfigurationService } from '../services/configuration.service'
import { ProductService } from '../services/product.service'
import { CookieService } from 'ngx-cookie-service';
import { CartService } from '../services/cart.service';
import { LineItemService } from '../services/line-item.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import { WishlistService } from '../services/wishlist.service';
import { WishedProductService } from '../services/wished-product.service';
import { WishedProduct } from '../models/wished-product.model';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NoShipLocationComponent } from './no-ship-location/no-ship-location.component';
import { OutOfStockComponent } from './out-of-stock/out-of-stock.component';
import { MaxTransactionExceededComponent } from './max-transaction-exceeded/max-transaction-exceeded.component';
import templateString from './product-detail.component.html';
import styleString from './product-detail.component.scss';
import { DomSanitizer } from '@angular/platform-browser'
import { SessionService } from '../services/session.service'
import { FollowService } from '../services/follow.service'
import { CountryService } from '../services/country.service';
import { SubfoldingLinkService } from '../services/subfolding-link.service';
import { CookiesResetService } from '../services/cookies-reset.service';
import { AccumulativePricePipe } from '../pipes/accumulative-price.pipe';
import { BuyNowLoginComponent } from './buy-now-login/buy-now-login.component';
import { BuyNowLoginV3Component} from './buy-now-login-v3/buy-now-login-v3.component';
import { ConfirmBuyNowWithCartComponent } from './confirm-buy-now-with-cart/confirm-buy-now-with-cart.component';
import CustomAlert  from '../../app/models/custom-alert.model';
import { DailyStockProductComponent } from './daily-stock-product/daily-stock-product.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare var $:any;
  declare global {
    interface Window {}
  }
@Component({
  selector: 'app-product-detail',
  template: templateString,
  styles: [ styleString ],
  providers:  [ ProductService, ConfigurationService, LineItemService, DatePipe, WishlistService, WishedProductService, SessionService, FollowService, CountryService, SubfoldingLinkService, AccumulativePricePipe]
})
export class ProductDetailComponent implements OnInit {

  @ViewChild(DailyStockProductComponent) dailyStockProduct: DailyStockProductComponent;

  contactSeller: FormGroup;
  personalization: FormGroup;
  brandFollow: FormGroup;
  product:any;
  submitted:boolean;
  disableAddToBasket:boolean;
  personalise:boolean;
  showDetails:boolean;
  askSellerSubmitted:boolean;
  addToWishlist: boolean = false;
  inputDisabled = false;
  id:number;
  breadcrum = [];
  breadcrumList = [];
  tabContent;
  currentUrl;
  currency;
  taxon_id;
  costAccumalator={};
  personalizationCost;
  otherGiftCost = 0;
  giftCost = 0;
  persolizationOptions=[]
  dropdownList = {};
  lineItem;
  lineItemObj;
  selectedItems = {};
  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'value',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  logoImageFile;
  currentDate;
  variantRrp=null;
  reviewsCount;
  rating;
  screenWidth;
  relatedProducts=[];
  vendorProducts=[];
  recentlyViewed=[];
  selectedCurrency = "USD";
  basePrice = '0';
  accessToken='';
  enableCustomizationPrice;
  giftCardLimit = {};
  isSelectedStore;
  cost = 0;
  fileCosts = 0;
  selectedFileAndOptionIds = [];
  fragment;
  vendorVisibility;
  askFromSeller;
  cartDropDown;
  mobCartDropDown;
  variants = [];
  variantSelected = false;
  variantOptinvalues = {};
  body;
  shortDescription;
  longDescription;
  deliveryDetails;
  @ViewChild('asksellerTabBtn') asksellerTabBtn;
  contactButtonClicked:boolean = false;
  variantOptions = {};
  countries= [];
  digitalCardOptionId = 0;
  otherGiftValueOptionId = 0;
  selectedVariant = null;
  disable_quantity;
  customizationId = null;
  maxCartTransaction;
  digitalRadiorbutton = null;
  physicalRadiorbutton = null;
  allDigital = false;
  buyNowButton = false;
  giftTypes = ["givex_digital", "blackhawk_digital", "tsgift_digital", "givex_physical", "blackhawk_physical", "tsgift_physical", "givex_both", "blackhawk_both", "tsgift_both"]
  totalItemInCard = 0;
  altText;
  enableProductInfo:boolean = false;
  sendViaSmsSelected = false;
  showAddCartBtn: boolean = false;
  dailyStockMeta :any = {};
  productQuantity :any = {};

  customOptions: OwlOptions = {
    loop: false,
    autoplay:false,
    autoplayTimeout:3000,
    nav: true,
    dots: false,
    autoplayHoverPause:true,
    stagePadding: 0,
    mouseDrag: true,
    //responsiveClass:true,
    responsive: {
      0:{
        items: 0
      },
      450:{
        items: 4,
        margin: 10,
        stagePadding: 0,
        nav: true
      }
    },
    navText: ["<",">"]
  };

  customOptionsMob: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplay:false,
    autoplayTimeout:1000,
    autoplayHoverPause:false,
    stagePadding: 0,
    mouseDrag: true,
    //responsiveClass:true,
    responsive: {
      0:{
        items: 1,
        margin: 20,
      },
      450:{
        items: 1,
        margin: 20,
      },
      768:{
        items: 1,
        margin: 52,
        stagePadding: 50
      }
    },
    navText: ["<",">"]
  };

  @Output('ngInit') initEvent: EventEmitter<any> = new EventEmitter();

  constructor(@Inject(DOCUMENT) protected document, public window: Window,
    protected productService: ProductService,
    protected cartService: CartService,
    protected lineItemService: LineItemService,
    protected configurationService: ConfigurationService,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected cookieService: CookieService,
    protected wishlistService: WishlistService,
    protected wishedProductService: WishedProductService,
    protected router: Router,
    protected datePipe: DatePipe,
    protected dialog: MatDialog,
    protected sanitizer: DomSanitizer,
    public sessionService: SessionService,
    public followService: FollowService,
    protected countryService: CountryService,
    public subfoldingLinkService: SubfoldingLinkService,
    public cookiesResetService: CookiesResetService,
    protected accumulativePrice: AccumulativePricePipe,
    public maxTransactionDialogRef: MatDialogRef<MaxTransactionExceededComponent>,
    public noShipDialogRef: MatDialogRef<NoShipLocationComponent>,
    public outOfStockDialogRef: MatDialogRef<OutOfStockComponent>,
    public buyNowLoginDialogRef: MatDialogRef<BuyNowLoginComponent>,
    public buyNowLoginV3DialogRef: MatDialogRef<BuyNowLoginV3Component>,
    public confirmBuyNowCartDialogRef: MatDialogRef<ConfirmBuyNowWithCartComponent>
  ){
    this.isSelectedStore = this.productService.isSelectedStore();
    this.accessToken = this.cookiesResetService.get('access_token');
    if(this.window["data"])
      this.vendorVisibility = this.window["data"]["storeItemVisibility"]["vendorVisibility"]
      this.askFromSeller =   this.window["data"]["storeItemVisibility"]["askSeller"]
      this.totalItemInCard = this.window["data"]['line_item_count']


    this.currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    const url = 'https://assets.pinterest.com/js/pinit.js';
    if (!document.querySelector(`script[src='${url}']`)) {
      let script = document.createElement('script');
      script.src = url;
      script['data-pin-build'] = 'parsePins';
      document.body.appendChild(script);
    }

    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    this.currentUrl = location.href;
    this.currency = this.cookiesResetService.get('curencySymbol');
    this.cookiesResetService.getItem('editLineItem');
    if (this.cookiesResetService.getItem('editLineItem')){
      this.lineItemObj = JSON.parse(this.cookiesResetService.getItem('editLineItem'));
      this.lineItem = this.lineItemObj.attributes.line_item_customizations.data;

      if (window.performance) {
        var splitUrl = this.router.url.split('/')
        if (this.lineItemObj){
          var currentId = JSON.stringify(splitUrl[splitUrl.length - 1])
          var productId = JSON.stringify(this.lineItemObj.attributes.product.data.id)
          if (performance.navigation.type == 1 && currentId == productId) {
          } else {
            this.cookiesResetService.removeItem('editLineItem')
          }
        }
      }
    }

    if(this.window.screen){
      this.screenWidth = this.window.screen.width;
    }
    this.cartDropDown = this.document.getElementsByClassName('dropdown-menu-right')[0];
    this.mobCartDropDown = this.document.getElementsByClassName('mob-cart-dropdown')[0];
    this.body = this.document.getElementsByTagName('body')[0];
  }

  //Receive data from daily stock product component
  selectedDailyStockVariants(data){
    // show add to cart button for daily stock
    this.showAddCartBtn =  data.showAddCartBtn;
    // disable add to cart button if quantity is zero
    this.disableAddToBasket = data.dailyStockMeta.disableAddToCart;

    this.personalization.patchValue({dailyStockVariants: data.variants});
    this.basePrice = data.price;
    this.dailyStockMeta = data.dailyStockMeta;
  }

  updateRecentlyViewed(){
    var recent = []
    if (this.cookiesResetService.getItem('recently_viewed'))
    {
      recent = JSON.parse(this.cookiesResetService.getItem('recently_viewed'))
      recent = recent.filter((el, i, a) => i === a.indexOf(el))
    }
    if (recent.indexOf(+this.product.id) != -1){
      const index: number = recent.indexOf(+this.product.id);
      recent.splice(index, 1);
    }
    if (recent.length > 3){
      var shift = recent.length - 3
      recent.splice(0 , shift)
    }
    recent.push(+this.product.id)
    this.cookiesResetService.setItem('recently_viewed', JSON.stringify(recent));
    this.productService.findAllProducts({"id_in": recent}).subscribe((data) => {
      this.recentlyViewed = data['data'];
    });
    if (this.accessToken ){
      this.productService.recent(recent).subscribe((data) => {});
    }
  }

  product_preview = false;

  fetchAllCountries(){
    this.countryService.removeCountriesWithStoreName().subscribe(
        (countries) => { this.countries = countries["data"]; }
    );
  }

  toggleProductDetail1() {
    // this.bootstrapCarousel();
    this.mobCarouselVariantDotRemoval();
    let top = document.getElementById('content');
    if (top !== null) top.scrollIntoView();
    this.enableProductInfo = false
  }

  ngOnInit() {
    this.fetchAllCountries();
    this.getCart();
    //  customization form
    this.personalization = this.formBuilder.group({
      quantity:[1,[Validators.required, Validators.min(1)]],
      vendor_id:[],
      variant:[],
      user_gift_amount: [0],
      receipient_email: [''],
      receipient_first_name: [''],
      receipient_last_name: [''],
      receipient_phone_number: [''],
      delivery_mode: [''],
      message: [''],
      sender_name: [''],
      delivery_mode_with_type: [''],
      buy_now_button: [false],
      dailyStockVariants: [],
      dailyStock:[false]
    });

    //Brand Follow Form

    this.brandFollow = this.formBuilder.group({
      follower_id:[],
      name:['',[Validators.required]],
      website:['', [Validators.required]],
      instagram: '',
      country_name: ['', [Validators.required]],
      details:['', [Validators.required]]
    });

    // customizations price calculation
    this.setCostAccumalator()

    this.enableCustomizationPrice = this.window["data"]["preferences"]["enable_customization_price"];

    if(this.window["data"]["maxCartTransaction"]) {
      this.maxCartTransaction = this.window["data"]["maxCartTransaction"];
    }


    this.selectedCurrency = this.cookiesResetService.get('curency');
    // contact seller form
    this.contactSeller = this.buildContactSellerForm();
    this.route.queryParams.subscribe(params => {
      if(params['product_preview']){
        this.product_preview = true
      }
    })
    // get product request
    this.productService.show(this.id).subscribe((data) => {
      this.product = data["data"];

      if(this.product.attributes.daily_stock){
        this.disableAddToBasket = true;
        this.personalization.patchValue({dailyStock: true})
        this.showAddCartBtn = false;
      }else
        this.showAddCartBtn = true;

      this.giftCardLimit['min'] = this.product.attributes.price_values.custom_price_range.min;
      this.giftCardLimit['max'] = this.product.attributes.price_values.custom_price_range.max;
      this.enableProductInfo = data["data"]['attributes']["enable_product_info"]
      // recipient details
      if(this.product.attributes.recipient_details_on_detail_page){
        let digitalServiceProvider = this.product.attributes.digital_service_provider;
        if(digitalServiceProvider == "tsgifts") digitalServiceProvider = "tsgift";
        if(this.product.attributes.delivery_mode == digitalServiceProvider + "_physical"){
          this.digitalRadiorbutton = false;
          this.physicalRadiorbutton = null;
        }
        if(this.product.attributes.delivery_mode == digitalServiceProvider + "_digital"){
          this.digitalRadiorbutton = null;
          this.physicalRadiorbutton = false;
        }
        var delivery_mode = "digital";
        if(this.product.attributes.delivery_mode == digitalServiceProvider + "_physical") delivery_mode = "physical";
        this.personalization.patchValue({
          delivery_mode: delivery_mode
        })
        this.personalization.controls.receipient_first_name.setValidators([Validators.required]);
        this.personalization.controls.receipient_last_name.setValidators([Validators.required]);
        this.personalization.controls.delivery_mode.setValidators([Validators.required]);
        this.personalization.controls.sender_name.setValidators([Validators.required]);
        if(delivery_mode == "digital" && (this.product.attributes.send_gift_card_via == "both" || this.product.attributes.send_gift_card_via == "email")){
          this.personalization.controls.receipient_email.setValidators([Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,15}$")]);
        }
      }

      if(this.product.attributes.default_quantity){
        this.personalization.controls['quantity'].setValue(this.product.attributes.default_quantity);
      }
      this.disable_quantity = this.product.attributes.disable_quantity;
      this.brandFollow.patchValue({follower_id: this.product['attributes']['brand_follow_data']['vendor_user_id']});
      this.variantRrp = this.decimalConversion(this.product.attributes.price_values.rrp);
      this.shortDescription = this.sanitizer.bypassSecurityTrustHtml(this.product['attributes']['description']);
      this.longDescription = this.sanitizer.bypassSecurityTrustHtml(this.product['attributes']['long_description']);
      this.deliveryDetails = this.sanitizer.bypassSecurityTrustHtml(this.product['attributes']['delivery_details']);
      // this.variants = this.product.attributes.variants.data
      this.product.attributes.variants.data.forEach(variant => {
        if(variant.attributes.archived == "false"){
          this.variants.push(variant)
        }
      })
      this.updateRecentlyViewed();

      this.product.attributes.option_types_names.forEach((optionTypeName, index) => {
        this.variantOptinvalues[optionTypeName] = this.listOptionValues(optionTypeName, index, this.product.attributes.option_types_names.length)
      })
      var that = this;
      this.product['attributes']['customizations']['data'].forEach(cus => {
        if(cus['attributes']['field_type']=='Drop-down' && cus['attributes']['label']=='Would you like a physical card?'){
          cus['attributes']['customization_options'].forEach(cusOpt => {
            if(cusOpt['label'] == 'No, please send a digital card.'){
              that.digitalCardOptionId = cusOpt['id'];
            }
          })
        }
        if(cus['attributes']['field_type']=='Drop-down'){
          this.customizationId = cus['id'];
          cus['attributes']['customization_options'].forEach(cusOpt => {
            if(cusOpt['customizable']){
              that.otherGiftValueOptionId = cusOpt['id'];
            }
          })
        }
      })

      this.checkIfItemIsWished(this.product);
      // setting product / breadcrum data
      if (this.product){
        this.taxon_id = this.product['attributes']['taxon_ids'][0];
        let tax_cat = this.product["attributes"]["taxon_category"];
          this.breadcrum = tax_cat["ancestors"];
          this.breadcrum.push(tax_cat);
          let slugs;
          let slug = "";
          this.breadcrum.forEach(item => {
            let obj = {};
            if(item["slug"] != "categories"){
              obj["name"] = item["name"];
              slugs = item['permalink'].split('/');
              slugs = slugs[slugs.length - 1];
              slug = slug + "/" + slugs;
              obj["slug"] = slug;
              this.breadcrumList.push(obj);
            }
          });
          this.breadcrumList.push({ name: this.product["attributes"]["name"], slug: "" })
      }

      // price setting
      this.basePrice = this.parseMultiplier(this.product["attributes"]["price_values"]["final_price"]);
      this.personalizationCost = 0;

      // product image
      if (this.lineItemObj){
        this.personalization.patchValue({message: this.lineItemObj.attributes.message});
        if(this.product.attributes.recipient_details_on_detail_page){
          var deliveryMode = this.lineItemObj.attributes.delivery_mode.split('_')[1];
          this.personalization.patchValue({
            delivery_mode: deliveryMode,
            receipient_email: this.lineItemObj.attributes.receipient_email,
            receipient_first_name: this.lineItemObj.attributes.receipient_first_name,
            receipient_last_name: this.lineItemObj.attributes.receipient_last_name,
            receipient_phone_number: this.lineItemObj.attributes.receipient_phone_number,
            sender_name: this.lineItemObj.attributes.sender_name
          })
          this.sendViaSmsSelected = this.lineItemObj.attributes.receipient_phone_number ? true : false;
          this.updateRecipientPhoneValidation({ target: { checked: this.sendViaSmsSelected }})
          if(deliveryMode=='physical'){
            this.personalization.controls.receipient_email.clearValidators();
            this.personalization.controls.receipient_email.updateValueAndValidity();
          }
        }
        this.setSelectedVariant();
        if(this.variantSelected || this.selectedVariant != null){
          this.basePrice = this.selectedVariant.attributes.price_values.price;
          this.variantRrp = this.selectedVariant.attributes.price_values.rrp;

          if( this.selectedVariant['attributes']['images']['data']['0']){
            this.tabContent = this.selectedVariant['attributes']['images']['data']['0']['attributes']['styles'][4]['url']
            this.altText  = this.selectedVariant['attributes']['images']['data']['0']['attributes']['alt']
          }
          else {
            this.setBaseTabContent()
          }
        }else{
          this.setBaseTabContent();
        }
      }else{
        this.setBaseTabContent();
      }

      // multiselect data population
      this.setMultipleSelectionData();

      this.populateMultipleSelectnData();
      // dynamic custom fields
      this.setCustomizationFields();

      // this.bootstrapCarousel();

      this.getSelectedVariant();
      //this.getMobItemCreds();
      if (!this.enableProductInfo){
        this.mobCarouselVariantDotRemoval();
      }

    },(err) => {
      let error = err["_body"];
      error = JSON.parse(error);
      console.log(error.error);
      this.openNoShipPopup();
    });

    this.personalise = false;
    this.showDetails= false;

    this.productService.relatedProducts(this.id).subscribe((data) => {
      this.relatedProducts = data['related_products']['data'];
      this.vendorProducts = data['vendor_products']['data'];
    });

    const urlTree = this.router.parseUrl(this.router.url);
    this.fragment = urlTree.fragment
    //console.log(this.fragment);
  }

  setBaseTabContent(){
    if (this.product['attributes']['base_image'] && this.product['attributes']['base_image']['data']){
      this.tabContent = this.product['attributes']['base_image']['data']['attributes']['styles'][4]['url']
      this.altText  = this.product['attributes']['base_image']['data']['attributes']['alt']

    }else{
      this.tabContent = (this.product['attributes']['variant_images']['data'].length > 0) ? this.product['attributes']['variant_images']['data']['0']['attributes']['styles'][4]['url'] : ""
      this.altText  =  (this.product['attributes']['variant_images']['data'].length > 0) ? this.product['attributes']['variant_images']['data']['0']['attributes']['alt'] : ""

    }
  }

  updateTypeValidations(value){
    if(value == "digital"){
      if(this.product.attributes.send_gift_card_via == "both" || this.product.attributes.send_gift_card_via == "email") {
        this.personalization.controls.receipient_email.setValidators([Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,15}$")]);
        this.personalization.controls.receipient_email.setValue("");
      }
    }else{
      this.personalization.controls.receipient_email.setValue("");
      this.personalization.controls.receipient_email.clearValidators();
    }
    this.personalization.controls.receipient_email.updateValueAndValidity();
  }

  updateValidations(customization){
    let valueOptionId = this.personalization.value[`${customization.attributes.label
        .split(' ').join('-')}${this.customizationId}`];
    if(valueOptionId && this.otherGiftValueOptionId == valueOptionId){
      this.onUserGiftAmount("blank");
      this.personalization.controls['user_gift_amount'].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$")
      ]);
      if(this.enableCustomizationPrice) {
        this.personalization.controls['user_gift_amount'].setValidators([
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(this.giftCardLimit["min"]),
          Validators.max(this.giftCardLimit["max"])
        ]);
      }
    }else{
      this.otherGiftCost = 0;
      this.personalizationCost = 0;
      this.basePrice = this.parseMultiplier(this.product["attributes"]["price_values"]["final_price"]);
      if(this.variantSelected || this.selectedVariant != null){
        this.basePrice = this.selectedVariant.attributes.price_values.price;
        this.variantRrp = this.selectedVariant.attributes.price_values.rrp;
      }
      this.resetGiftAmount();
    }
  }

  resetGiftAmount(){
    this.personalization.controls['user_gift_amount'].setValue("");
    this.personalization.controls['user_gift_amount'].clearValidators();
    this.personalization.controls['user_gift_amount'].updateValueAndValidity();
  }

  onUserGiftAmount(keyPressedEvent) {
    if(keyPressedEvent === "blank") {}
    else{
      this.otherGiftCost = parseInt(keyPressedEvent.target.value);
    }
    if(this.otherGiftCost > 0){
      this.personalizationCost = this.cost + this.otherGiftCost;
      this.basePrice = '0';
    }else{
      this.personalizationCost = this.cost;
      this.basePrice = this.parseMultiplier(this.product["attributes"]["price_values"]["final_price"]);
      if(this.variantSelected || this.selectedVariant != null){
        this.basePrice = this.selectedVariant.attributes.price_values.price;
        this.variantRrp = this.selectedVariant.attributes.price_values.rrp;
      }
    }
  }

  buildContactSellerForm(){
    let sellerForm = this.formBuilder.group({
      guest_name: ['', Validators.required],
      guest_email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,15}$")]],
      title: ['', Validators.required],
      vendor_id: ['', Validators.required],
      product_id: ['', Validators.required],
      myRecaptcha: [null, Validators.required]
    });
    return sellerForm;
  }

  listOptionValues(optionType, index, lengthOfOptionTypes){
    let variantOptionvales = [];
    let selectedOptionValue;
    var variantId = this.lineItemObj?.relationships?.variant?.data?.id;
    this.variants.forEach(variant => {
      selectedOptionValue = variant.attributes.option_values.find(obj => obj.option_type_name == optionType);
      if(selectedOptionValue){
        selectedOptionValue.disabled = false;
        selectedOptionValue.selected = false;
        if(variantId && variantId === variant.id){
          selectedOptionValue.selected = true;
        }else if(!variantId){
          if(index == 0) {
            if(lengthOfOptionTypes > 1){
              selectedOptionValue.disabled = false;
            }else{
              if(variant.attributes.out_of_stock){
                selectedOptionValue.disabled = true;
              }else{
                selectedOptionValue.disabled = false;
              }
            }
          }
          else{selectedOptionValue.disabled = true;}
        }
        variantOptionvales.push(selectedOptionValue)
      }
    });
    if(variantId && variantOptionvales.length > 1){
      variantOptionvales = variantOptionvales.sort((a, b) => Number(b.selected) - Number(a.selected));
    }
    return this.getUnique(variantOptionvales, "name")
  }

  getUnique(arr, comp) {
    const unique =  arr.map(e => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => arr[e]).map(e => arr[e]);
    return unique;
  }

  sortAndJoin(arr){ return arr.sort((a,b) => a.localeCompare(b)).join(); }

  variantSelection(optionTypeName, optionValueName, optionValuePresentation){
    this.variantOptions[optionTypeName] = optionValuePresentation;
    var selectedVariantOptions = [];
    Object.keys(this.variantOptions).forEach(key => { selectedVariantOptions.push(key + ': ' + this.variantOptions[key]); });
    for (var variant of this.variants) {
      if(this.sortAndJoin(variant.attributes.custom_options_text.split(', ')) == this.sortAndJoin(selectedVariantOptions)){
        let var_price = variant.attributes.price_values;
        if(this.otherGiftCost <= 0){
          this.basePrice = this.parseMultiplier(var_price.price);
          this.variantRrp = this.decimalConversion(var_price.rrp);
        }
        break;
      }
    }

    this.variantOptinvalues[optionTypeName].forEach(optionValue => {
      if(optionValue.name == optionValueName){
        optionValue.selected = true;
      }else{
        optionValue.selected = false;
      }
    });
    if(this.product.attributes.option_types_names[0] == optionTypeName){
      this.product.attributes.option_types_names.forEach((optionTypeProductName,index) => {
        if(optionTypeProductName != this.product.attributes.option_types_names[0]){
          this.variantOptinvalues[optionTypeProductName].forEach(optionValue => {
            optionValue.selected = false;
          });
        }
      });
      let enabledOptions = [];
      this.variants.forEach(variant => {
        let findSelectOptionvalueinVariants = variant.attributes.option_values.find(obj => obj.name == optionValueName);
        if(findSelectOptionvalueinVariants){
          variant.attributes.option_values.forEach(ov => {
            this.product.attributes.option_types_names.forEach((optionTypeProName,index) => {
              let optinValuesTobeEnabled = this.variantOptinvalues[optionTypeProName].find(obj => obj.name == ov.name);
              if(optinValuesTobeEnabled && index != 0 && !variant.attributes.out_of_stock){
                enabledOptions.push(ov.name)
                optinValuesTobeEnabled.disabled = false
              }
            })

          })
        }else{
          variant.attributes.option_values.forEach(ov => {
            this.product.attributes.option_types_names.forEach((optionTypeName,index) => {
              let optinValuesTobeEnabled = this.variantOptinvalues[optionTypeName].find(obj => obj.name == ov.name);
              if(optinValuesTobeEnabled && index != 0 && !enabledOptions.includes(optinValuesTobeEnabled.name)){
                optinValuesTobeEnabled.disabled = true
              }
            })

          })
        }
      });
    }

    this.setSelectedVariant();
    if(this.variantSelected || this.selectedVariant != null){
      if (this.selectedVariant['attributes']['images']['data']['0']){
        this.tabContent = this.selectedVariant['attributes']['images']['data']['0']['attributes']['styles'][4]['url'];
        this.altText = this.selectedVariant['attributes']['images']['data']['0']['attributes']['alt']
        var images = $(".prod-image");
        let mob_carousel = document.querySelector(".product-carousel-mob");
        let owl_items = mob_carousel.querySelectorAll(".owl-item");
        let owl_dots = mob_carousel.querySelectorAll(".owl-dot");
        let items_stages = [];
        let _sum = 0;
        owl_items.forEach((item, index)=>{
        	//console.log(index, item)
          let itemStyle = getComputedStyle(item);
          let item_width = parseFloat(itemStyle.width);
          let item_margin = parseInt(itemStyle.marginRight);
          let item_stage;
          if (index == 0){
            items_stages.push(0);
          }
          else {
            items_stages.push(Math.ceil(item_width + item_margin));
          }
        });
        let sums = items_stages.map(value => _sum += value);
        //console.log(sums);

        owl_dots.forEach((item, index)=>{
          item.setAttribute('id','dot-btn-'+index)
        });

        for(var i = 0; i < images.length; i++){  
          var url = images[i].src.replace(/^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//, '/');
          let tabContentUrl = this.tabContent.replace(/^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//, '/');
          if(tabContentUrl === url){
            $(".owl-item").removeClass("active");
            images[i].closest(".owl-item").classList.add("active");
            $('.product-carousel-mob .owl-stage').css('transform','translate3d(-'+sums[i]+'px, 0px, 0px)');
            $('.product-carousel-mob .owl-stage').css('transition','all 0.25s ease 0s');
            $('.product-carousel-mob .owl-dots .owl-dot').removeClass('active');
            $('.product-carousel-mob .owl-dots .owl-dot').removeClass('var-active');
            $('.product-carousel-mob .owl-dots .owl-dot#dot-btn-'+[i]).addClass('active var-active');
            if (i != 0) {
              $('.product-carousel-mob.tab .owl-nav .owl-prev').removeClass('disabled');
            }
            console.log(sums[i]);
            break;
          }
        }
      }
    }
  }

  setSelectedVariant(){
    let selectedVariantOptionTypes = [];
    this.product.attributes.option_types_names.forEach(optionTypeName => {
      let userSelectedOptionType = this.variantOptinvalues[optionTypeName].find(obj => obj.selected == true)
      if(userSelectedOptionType){
        selectedVariantOptionTypes.push(userSelectedOptionType.name)
      }
    })
    if(this.product.attributes.option_types_names.length == selectedVariantOptionTypes.length){
      this.variants.forEach(variant => {
        let optionValuesNameArray = [];
        variant.attributes.option_values.forEach(ov => {
          optionValuesNameArray.push(ov.name)
        })
        if(optionValuesNameArray.sort().join(',') == selectedVariantOptionTypes.sort().join(',')){
          this.personalization.controls['variant'].setValue(+ variant.id);
          this.variantSelected = true;
          this.selectedVariant = variant
        }
      })
    }else {
      this.variantSelected = false
    }
  }

  getMobItemCreds(){
    setTimeout(function () {
      /*$( ".product-carousel-mob .owl-item" ).each(function( index ) {
        console.log( index + ": " + $( this ).computedStyleMap().get('width'));
      });*/
      let mob_carousel = document.querySelector(".product-carousel-mob");
      let owl_items = mob_carousel.querySelectorAll(".owl-item");
      let items_stages = [];
      let _sum = 0;
      owl_items.forEach((item, index)=>{
      	//console.log(index, item)
        let itemStyle = getComputedStyle(item);
        let item_width = parseFloat(itemStyle.width);
        let item_margin = parseInt(itemStyle.marginRight);
        let item_stage;
        if (index == 0){
          items_stages.push(0);
        }
        else {
          items_stages.push(Math.ceil(item_width + item_margin));
        }
      });
      let sums = items_stages.map(value => _sum += value);
      //console.log(sums);
    }, 500);
  }

  resetVariantSelection(){
    for(var variantOptin in this.variantOptinvalues){
      this.variantOptinvalues[variantOptin].forEach(optinValue => { optinValue.selected = false; });
    }
    this.variantSelected = false;
  }

  resetVariantOptions(){
    var optionTypesName = this.product.attributes.option_types_names[0];
    this.variants.forEach(variant => {
      variant.attributes.option_values.forEach(ov => {
        if(optionTypesName !== ov.option_type_name){
          ov.disabled = true;
        }else{
          ov.disabled = false;
        }
      })
    });
  }

  setTabContent(imgIndex, variantId, altText){
    this.tabContent = imgIndex;
    this.altText = altText;
    this.resetVariantSelection();
    this.variantSelected = false;
    this.selectedVariant = null;
    var optionTypesName = this.product.attributes.option_types_names[0];

    if(this.product.attributes.master_variant === variantId){
      this.resetVariantOptions();
      return;
    }
    const selectedOptions = {};
    this.variants.forEach(variant => {
      if(variant.id === variantId.toString() && !variant.attributes.out_of_stock){
        this.variantSelected = true;
        this.selectedVariant = variant;
        variant.attributes.option_values.forEach(function(ov) {
            let variantOptionValue = this.variantOptinvalues[ov.option_type_name].find(opv => opv.id == ov.id)
            variantOptionValue.selected = true;
            ov.selected = true;
            variantOptionValue.disabled = false;
            ov.disabled = false;
            selectedOptions[ov.option_type_name] = ov.name;
        }.bind(this));
      }else{
        variant.attributes.option_values.forEach(ov => {
          if(optionTypesName !== ov.option_type_name){
            ov.disabled = true;
          }else{
            ov.disabled = false;
          }
          if(this.variantOptinvalues[ov.option_type_name].length === 1 && !variant.attributes.out_of_stock){
            ov.selected = true;
            ov.disabled = false
            selectedOptions[ov.option_type_name] = ov.name;
          }
        });
      }
    });
  
    this.variants.forEach(variant => {
      if (variant.id !== variantId.toString() && variant.attributes.quantity !== 0) {
        variant.attributes.option_values.forEach(ov => {
          if (selectedOptions[ov.option_type_name] === ov.name) {
            ov.selected = true;
            ov.disabled = false;
          }
        });
      }
    });
  }

  checkIfItemIsWished(product){
    if(this.accessToken){
      this.wishlistService.findUserList().subscribe(
        (wishlist) => {
          let wishedItems = wishlist["data"].attributes.wished_products.data
          wishedItems.forEach( wishedProdut => {
            if(wishedProdut.attributes.prodcut_id == product.id){
              product.wished = true
              product.wishedProductId = wishedProdut.id
            }
          });
        });
    }else{
      var wishedProduts = JSON.parse(this.cookiesResetService.getItem('guest_wish_list'))
      if(wishedProduts && wishedProduts.includes(product.id)){
        product.wished = true
      }else{
        product.wished = false
      }
    }

  }

  get f() { return this.contactSeller.controls; }
  get f1() { return this.personalization.controls; }

  setMultipleSelectionData(){
    var index = 0;
    let obj = this.product['attributes']['customizations']['data'];
    for (let key in obj) {
      if (obj[key]['attributes']['field_type']=='Multiple Select'){
        let options = obj[key]['attributes']['customization_options'];
        let dataSource = [];
        if (obj[key]['attributes']['field_type'] == "Multiple Select"){
          this.selectedItems[obj[key]['id']] = [];
        }
        options.forEach(option => {
          let data={};
          data['id'] = option['id'];
          let price = this.decimalConversion(this.parseMultiplier(option['price']));
          if(obj[key]['attributes']['show_price']){
            data['value'] = option['label'] + " + " + price;
          }else{
            data['value'] = option['label'];
          }
          dataSource.push(data);
        })
        this.dropdownList[index] = dataSource;
      }
      index = index + 1;
    }
  }

  populateMultipleSelectnData(){
    if (this.lineItem){
      this.lineItem.forEach(option => {
        if (option.attributes.field_type == "Multiple Select"){
          var array = [];
          if (this.selectedItems[option.attributes.customization_id]){
            var data = {};
            data['id'] = option.attributes.customization_option_id;
            let price = this.decimalConversion(this.parseMultiplier(option['attributes']['price']));
            data['value'] = option['attributes']['title'] + " + " + price;
            array  = this.selectedItems[option.attributes.customization_id];
            array.push(data);
          }
          this.selectedItems[option.attributes.customization_id] = array;
        }
      });
    }
  }

  setCustomizationFields(){
    this.personalization.controls['variant'].setValue(this.product.attributes.master_variant);
    this.personalization.controls['vendor_id'].setValue(this.product['attributes']['vendor_id']);
    if (this.lineItemObj){
      this.personalization.controls['quantity'].setValue(this.lineItemObj.attributes.quantity);
      var personalizations = this.lineItemObj.attributes.line_item_customizations;

      if(this.lineItemObj.attributes.custom_price > 0)
        this.basePrice = '0';

      if (personalizations){
        personalizations.data.forEach(customization => {
            if(customization['attributes']['customizable']){
              this.otherGiftCost = this.lineItemObj.attributes.custom_price;
              this.personalization.patchValue({ user_gift_amount: this.otherGiftCost });
            }else{
              this.otherGiftCost= 0;
            }
        });
      }
    }

    var index = 0;
    this.product['attributes']['customizations']['data'].forEach(customization => {
      // let fieldName = customization['attributes']['label'].split(' ').join("-") + customization.id
      let fieldName = customization['attributes']['label'].split(' ').join("-") + customization.id
      let fieldType = customization['attributes']['field_type']
      let customOption = []
      customization['attributes']['customization_options'].forEach(options => {
        let optionType = {}
        let label='';
        if (fieldType == 'Field' || fieldType == 'Area'|| fieldType == 'Date'){
          optionType['fieldType'] = "input"
        }

        label = options['id']
        optionType[label] = this.to_f(this.parseMultiplier(options['price']));
        if (fieldType == 'Multiple Select'){
          optionType['value'] = options['label']
        }else{
          optionType['value'] = options['value']
        }
        customOption.push(optionType)
      });
      this.costAccumalator[fieldName] = customOption

      let controls;
      if(fieldType == 'Checkbox') {
        controls = new FormArray([]);

        var checkboxValue = false
        customization['attributes']['customization_options'].forEach(options => {
          controls.push(new FormControl( checkboxValue));
        });
      }else{
        controls = new FormControl('')
      }

      if (this.lineItem && this.lineItem[index] && fieldType != 'File' ){
        if (this.lineItem[index].attributes.name.split(' ').join("-") + customization.id == fieldName){

          if(fieldType == 'Checkbox') {
            var checkboxIndex = 0;
            controls.controls.forEach(option => {
              // if(selectedCheckBoxes.indexOf(customization['attributes']['customization_options'][checkboxIndex]['id']) != -1){
              if (this.lineItem[index] && (customization['attributes']['customization_options'][checkboxIndex]['id'] == this.lineItem[index]['attributes']['customization_option_id'])){
                option.setValue(true)
                index  = index + 1;
              }
              checkboxIndex = checkboxIndex + 1;
            });

          }else{
            if (fieldType == 'Field' || fieldType == 'Area' || fieldType == 'Date'){
              controls = new FormControl(this.lineItem[index].attributes.value)
            }else if (fieldType == 'Multiple Select'){
              if (this.selectedItems[customization.id]){
                index = index + this.selectedItems[customization.id].length - 1
              }
            }else{
              controls = new FormControl(this.lineItem[index].attributes.customization_option_id)
            }
            index  = index + 1;
          }
        }
      }
      this.personalization.addControl(fieldName, controls);
      this.updateValidations(customization);
    });
  }

  onAddToBasket(){
    this.disableAddToBasket = true;
    // Check errors in daily Stock Product
    if(this.dailyStockMeta?.errors?.length > 0) return;

    var pack_size : any = this.product["attributes"]["pack_size"];
    var moq : any = this.product["attributes"]["minimum_order_quantity"];
    var min_default_quantity = this.product["attributes"]["minimum_order_quantity"];
    var max_order_quantity = this.product["attributes"]["preferences"]["maximum_order_quantity"];
    var line_item_quantity : any = this.personalization.controls['quantity'].value;
    var cart_value, product_price;

    if(!this.product["attributes"]["daily_stock"]){
      if(line_item_quantity < min_default_quantity) {
        CustomAlert.show('MOQ_QUANTITY',{ moq_quantity: min_default_quantity });
        this.disableAddToBasket = false;
        return;
      }
      if (line_item_quantity%pack_size != 0 || moq > line_item_quantity){
        CustomAlert.show('MOQ_TEXT',{moq});
        this.disableAddToBasket = false
        return;
      }
      var productQty;
      if(this.buyNowButton == true && !this.lineItemObj) productQty = line_item_quantity;
      else productQty = (this.productQuantity[this.product.id] || 0) + line_item_quantity;
      if(this.lineItemObj) {
        productQty = this.productQuantity[this.product.id] - this.lineItemObj.attributes.quantity;
        productQty += line_item_quantity;
      }
      if(max_order_quantity != 0 && productQty > max_order_quantity) {
        CustomAlert.show('MAXIMUM_ORDER_QUANTITY',{ max_quantity: max_order_quantity });
        this.disableAddToBasket = false;
        this.buyNowButton = false;
        return;
      }
    }

    product_price = this.accumulativePrice.transform([this.basePrice, this.personalizationCost]);

    var order_token = this.cookiesResetService.get('order_token');
    if(order_token && order_token != "null"){
      const parseField = "?&fields[cart]=updated_at,price_values,token&selected=true";
      this.cartService.getCart(parseField).subscribe(cartData => {
        var cart = cartData["data"]["attributes"];
        var order_token = this.cookiesResetService.get('order_token');
        var cart_token = cart["token"];
        this.cookiesResetService.set('order_token', cart["token"]);
        cart_value = cart["price_values"]["item_total"];
        if(order_token != cart_token) this.cookiesResetService.set('order_token', cart_token);
        if(this.checkIfTransactionExceedsMax(cart_value, this.to_f(product_price) * line_item_quantity, this.lineItemObj)) {
          this.disableAddToBasket = false;
          return;
        }
        this.addItemToBasket();
      });
    }else{
      this.cartService.createCart().subscribe((data) => {
        var cart = data["data"];
        cart_value = cart["attributes"]["price_values"]["item_total"];
        // this.cookiesResetService.setItem('order_token', JSON.stringify(cart['attributes']["token"]));
        this.cookiesResetService.set('order_token', cart['attributes']["token"]);
        if(this.checkIfTransactionExceedsMax(cart_value, this.to_f(product_price) * line_item_quantity)) {
          this.disableAddToBasket = false;
          return;
        }
        this.addItemToBasket();
      });
    }
  }

  getCart(parseField=null){
    if(!parseField) parseField = "?&fields[cart]=product_quantities";
    this.cartService.getCart(parseField).subscribe(cartData => {
      var cart = cartData["data"]["attributes"];
      this.productQuantity = cart.product_quantities;
    },(error: HttpErrorResponse) => {
      console.error('Error fetching cart data:', error);
    });
  }

  sendGTMRequest(product,line_item_quantity,data)
  {
    const product_price = this.accumulativePrice.transform([this.basePrice, this.personalizationCost]);
    window["dataLayer"].push({
      'event': 'addedToCart',
      'variant_sku': data['variant_sku'],
      'product_name': product["attributes"]["name"].toString(),
      'product_sku': product["attributes"]["sku"].toString(),
      'product_qty': line_item_quantity.toString(),
      'product_price': product_price,
      'product_currency': this.selectedCurrency.toString(),
      'store': window['data']['storeName']
    });
  }

  addItemToBasket() {
    this.disableAddToBasket = true;
    this.submitted = true;
    this.setSelectedVariant();
    if (this.cookiesResetService.get('order_token')){
      // dailyStockMeta presence means it's daily stock variant and no option type/value selection required
      if(Object.keys(this.dailyStockMeta || {}).length == 0 && this.variants.length > 0 && this.variantSelected == false){
        CustomAlert.show('SELECT_VARIANT');
        this.disableAddToBasket = false
      }else{

        if (this.personalization.invalid) {
          const invalid = [];
          const controls = this.personalization.controls;
          for (const name in controls) {
            if (controls[name].invalid) {
              invalid.push(name);
            }
          }
          this.disableAddToBasket = false;
          return;
        }else{
          this.selectedFileAndOptionIds.forEach(options => {
            this.persolizationOptions.push(options)
          })

          var delivery_mode = this.personalization.controls.delivery_mode.value
          let digitalServiceProvider = this.product.attributes.digital_service_provider;
          if(digitalServiceProvider == "tsgifts") digitalServiceProvider = "tsgift";
          if(this.product.attributes.recipient_details_on_detail_page){
            this.personalization.patchValue({
              delivery_mode_with_type: digitalServiceProvider + "_" + delivery_mode
            })
          }
          this.personalization.patchValue({
            buy_now_button: this.buyNowButton
          })

          this.persolizationOptions.forEach(option => {
            if(option.customization_option_id == this.otherGiftValueOptionId){
              option['gift'] = {};
              option['gift']['user_gift_amount'] = this.personalization.value.user_gift_amount;
            }
          });
          if (this.lineItem){
            var message = this.personalization.controls.message.value;
            let variant_id = this.personalization.controls.variant.value;
            let stockedQuantity = this.personalization.controls.quantity.value;

            // While editing daily stock variant get current variant
            // (only one variant can be edited so index 0 is only and current variant)
            if(this.product.attributes.daily_stock){
              let stockedVariant = this.personalization.controls.dailyStockVariants.value[0];

              variant_id = stockedVariant["id"];
              stockedQuantity = stockedVariant["quantity"];
            }

            this.lineItemService.updateLineItem(this.lineItemObj.id, this.persolizationOptions, message, variant_id, stockedQuantity, this.personalization.controls, true).subscribe((data) => {
              this.productQuantity = data['product_quantities'];
              CustomAlert.confirm('CUSTOMIZATION_UPDATED',{},{
                showYesActionBtn:true,
                yesActionText: 'OK',
                yesActionCallback: (self) => {
                  setTimeout(() => {
                    if(self.lineItemObj['fromDeliveryPage'] && (['v2', 'v3'].includes(window['data']['checkoutFlow'])))
                      window.location.href = self.subfoldingLinkService.linkWithSubdomainOrNot("/checkout");
                    else
                      window.location.href = self.subfoldingLinkService.linkWithSubdomainOrNot("/cart");
                  }, 500);
                },
                self: this
              });
            },(err:HttpErrorResponse)=>{
              this.disableAddToBasket = false;
              this.personalization.controls['quantity'].setValue(this.lineItemObj.attributes.quantity);
              let error = err["_body"];
              if(error) error = JSON.parse(error);
              else error = err.error;
              CustomAlert.show('ERROR',{ error: error.error });
          });
          } else {
            if((window['data']['checkoutFlow']== "v2" ) && this.buyNowButton && this.totalItemInCard > 0){
              this.ConfirmForAlreadyCart(delivery_mode);
            }else if((window['data']['checkoutFlow']== "v3" ) && this.buyNowButton){
              this.buyNowV3(delivery_mode);
            }else{
              this.addLineItemToBasket(delivery_mode);
            }
          }

        }

      }

    }
  }

  addLineItemToBasket(delivery_mode){
    this.cartService.addItem(this.personalization.controls,this.persolizationOptions).subscribe((data) => {
      this.productQuantity = data['product_quantities'];
      this.sendGTMRequest(this.product, this.personalization.controls['quantity'].value,data)
      this.allDigital = data['all_digital'];
      this.totalItemInCard = data['line_item_count'];
      this.disableAddToBasket = false;
      this.cartService.notifyItemAdded();
      if(this.dailyStockProduct) this.dailyStockProduct.resetFormWithPreferredDate();
      this.product['attributes']['customizations']['data'].forEach(customization => {
        let fieldName = customization['attributes']['label'].split(' ').join("-") + customization.id
        let fieldType = customization['attributes']['field_type']
        if(fieldType == 'Checkbox') {
          this.personalization.controls[fieldName]["controls"].forEach(option => {
            option.setValue(false)
          });
        }else{
          this.personalization.controls[fieldName].setValue("");
        }
      })
      if(this.product.attributes.default_quantity){
        this.personalization.controls['quantity'].setValue(this.product.attributes.default_quantity);
      }else{
        this.personalization.controls['quantity'].setValue(1);
      }
      if(this.product.attributes.recipient_details_on_detail_page){
        this.personalization.controls.receipient_email.setValue("");
        this.personalization.controls.delivery_mode.setValue(delivery_mode);
        this.personalization.controls.receipient_first_name.setValue("");
        this.personalization.controls.receipient_last_name.setValue("");
        this.personalization.controls.receipient_phone_number.setValue("");
        this.personalization.controls.sender_name.setValue("");
        this.personalization.controls.message.setValue("");
      }
      this.resetGiftAmount();
      // this.resetVariantSelection();
      this.personalizationCost = 0;
      this.basePrice = this.parseMultiplier(this.product["attributes"]["price_values"]["final_price"]);
      // if(this.window["gtag"]){
      //   console.log("here")
      //   this.window["gtag"]('event', 'add_to_cart', {
      //     currency: this.selectedCurrency,
      //     items: [{
      //       id: this.product['attributes']['slug'],
      //       name: this.product['attributes']['name'],
      //       brand: this.product['attributes']['vendor_name'],
      //       price: this.product['attributes']['price'],
      //       quantity: this.personalization.value["quantity"]
      //     }],
      //     value: this.personalization.value["quantity"] * parseFloat(this.product['attributes']['price'])
      //   });
      // }

      this.submitted = false;
      let url = this.subfoldingLinkService.linkWithSubdomainOrNot("/storefront/cart/data")
      var toggle = {}
      if(this.buyNowButton) toggle = {toggle: false}
      $.ajax({url: url, dataType: "script", data: toggle});
      if((window['data']['checkoutFlow']=="v2") && this.buyNowButton) this.BuyNow();
      if((window['data']['checkoutFlow']=="v3") && this.buyNowButton){
        if(this.allDigital){
          window.location.href = this.subfoldingLinkService.linkWithSubdomainOrNot('/checkout');
        }else{
          window.location.href = this.subfoldingLinkService.linkWithSubdomainOrNot('/cart');
        }
      }
      // this.cartService.refreshCart()
      // window.location.reload();
    },(err: HttpErrorResponse) => {
      // this.openOutOfStockPopup();
      let error = err["_body"];
      if(error) error = JSON.parse(error);
      else error = err.error;
      CustomAlert.show('ERROR',{ error: error.error });
      this.disableAddToBasket = false;
    });
  }

  onAddToWishlist(){
    this.addToWishlist = !this.addToWishlist;
  }

  askSeller() {
    this.askSellerSubmitted = true;

    this.contactSeller.patchValue({
      vendor_id: this.product['attributes']['vendor_id'],
      product_id: this.product['id']
    });

    if (this.contactSeller.invalid){
      CustomAlert.show('FILL_ALL_FIELDS');
      return;
    }else{
      const fd = new FormData();
      fd.append("question", JSON.stringify(this.contactSeller.value));
      this.configurationService.askSeller(fd).subscribe((data) => {
        CustomAlert.show('THANKS_FOR_QUESTION_TEXT');
        this.contactSeller = this.buildContactSellerForm();
        this.askSellerSubmitted = false;
      });
    }
  }

  onPersonalise() {
    this.personalise = true;
    this.inputDisabled = false;
  }

  showMore() {
    this.showDetails = true;
  }

  setCostAccumalator(){
    var that = this;
    that.cost = 0;
    this.personalization.valueChanges.subscribe(data => {
      this.persolizationOptions = [];
      that.cost = 0;
      for (let key in data) {
        var field= {};
        if (data[key] != "" && key != 'quantity' && key != 'variant' && key != 'vendor_id'){

          if (this.costAccumalator[key] && this.costAccumalator[key].constructor === Array && this.costAccumalator[key][0]['fieldType'] == undefined){
            var index = 0;
            this.costAccumalator[key].forEach(option => {
              for (let id in option) {
                if (data[key].constructor === Array){
                  if (data[key][index] ==  false || data[key][index] == true ){
                    if (data[key][index] == true){
                      var checkBoxObject = this.costAccumalator[key][index];
                      field={};
                      for (let checkbox in  checkBoxObject){
                        if (checkbox == 'value'){
                          field["value"]=checkBoxObject['value'];
                        }else{
                          field["customization_option_id"]=checkbox;
                          this.persolizationOptions.push(field);
                          that.cost  = that.cost + this.to_f(checkBoxObject[checkbox]);
                        }
                      }
                    }
                    index = index + 1
                  }
                  data[key].forEach(selection => {
                    var multiOption = selection['id']
                    if (multiOption == id){
                      field={};
                      field["customization_option_id"]=id;
                      field["value"]=option["value"];
                      this.persolizationOptions.push(field);
                      that.cost = that.cost + this.to_f(option[id]);
                    }
                  });
                }else{
                  if (data[key] == id){
                    field={};
                    field["customization_option_id"]=data[key];
                    field["value"]=option["value"];
                    this.persolizationOptions.push(field);
                    that.cost = that.cost + this.to_f(option[id]);
                  }else if(data[key]){

                  }
                }
              }
            });
          }else{
            field={}
            if(this.costAccumalator[key]){
              field["customization_option_id"]=Object.keys(this.costAccumalator[key][0])[0];
              field["value"]=data[key];
              this.persolizationOptions.push(field);
              that.cost = that.cost + this.to_f(this.costAccumalator[key][0][field["customization_option_id"]]);
            }
          }
        }
      }
      if (this.product){
        var totalCost = 0;
        that.selectedFileAndOptionIds.forEach(price => {
          totalCost = totalCost + price["price"]
        });
        this.personalizationCost = that.cost + totalCost + that.otherGiftCost;
      }

    });
  }

  // apply tax to customizations
  parseMultiplier(stringAmount){
    var multiplier = 1;
    if(this.product) multiplier = this.product.attributes.price_values.tax.rate + 1;

    return this.accumulativePrice.transform([stringAmount], null, { multiplier: multiplier });
  }

  onSelectedFile(event, cus_id) {
    var that = this;
    if (event.target.files.length != 0) {
      const file = event.target.files[0];
      if(file.type.includes("image")){
        const fd = new FormData()
        fd.append("file", file, file.name)
        this.lineItemService.uploadimage(fd).subscribe(imageData => {
              var image = imageData["data"];
              this.product['attributes']['customizations']['data'].forEach(customization => {
                if(customization['id'] == cus_id){
                  var costOfFile = customization['attributes']['customization_options'][0]['price'];
                  costOfFile = this.parseMultiplier(costOfFile);
                  var idOfOption = customization['attributes']['customization_options'][0]['id'];
                  that.fileCosts = that.fileCosts + this.to_f(costOfFile);
                  var alreadyPresent = false;
                  that.selectedFileAndOptionIds.forEach(option => {
                    if(option['customization_option_id'] == idOfOption){
                      option['value'] = image.id;
                      alreadyPresent = true;
                    }
                  })
                  if(alreadyPresent == false){
                    that.selectedFileAndOptionIds.push({customization_option_id: idOfOption, value: image.id, price: this.to_f(costOfFile)});
                  }
                }
              })
              var totalCost = 0;
              that.selectedFileAndOptionIds.forEach(price => {
                totalCost = totalCost + price["price"];
              })
              that.personalizationCost  = that.cost + totalCost + that.otherGiftCost;
           },
           (error: any) => {
             console.log('error in subscribe: '+ error);
             }
         );
      }else{
        CustomAlert.show('ALLOWED_IMAGES_TEXT');
      }
    }
  }

  onClickContact(value:boolean) {
    this.contactButtonClicked = value;
  }

  addProductToWishList(product, status){
    if(this.accessToken){
      if(status == "remove"){
        this.wishedProductService.delete(product.wishedProductId).subscribe( obj => {
          product.wished = false
          product.wishedProductId = null
        });
      }else{
        let wishedProdutData = new WishedProduct(product.id);
        this.wishedProductService.save(wishedProdutData).subscribe((data: any)  =>{
          product.wished = true
          product.wishedProductId = data.wished_product_id
        });
      }
    }else{
      if(this.cookiesResetService.getItem('guest_wish_list')){
        var wishedProduts = JSON.parse(this.cookiesResetService.getItem('guest_wish_list'))
        if(status == "remove"){
          product.wished = false
          var index = wishedProduts.indexOf(product.id);
          wishedProduts.splice(index, 1);
        }else{
          product.wished = true
          wishedProduts.push(product.id);
        }
        this.cookiesResetService.setItem('guest_wish_list', JSON.stringify(wishedProduts));
      }else{
        product.wished = true
        this.cookiesResetService.setItem('guest_wish_list', JSON.stringify([product.id]));
      }
    }

    // if(status != "remove"){
    //   if(this.window["gtag"]){
    //     this.window["gtag"]('event', 'add_to_wishlist', {
    //       currency: this.selectedCurrency,
    //       items: [{
    //         id: product.attributes.slug,
    //         name: product.attributes.name,
    //         brand: product.attributes.vendor_name,
    //         price: product.attributes.price
    //       }],
    //       value: product.attributes.price
    //     });
    //   }
    // }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  reviewsRatings(reviews){
    this.reviewsCount = reviews.reviews;
    this.rating = reviews.rating;
  }

  // onScriptLoad() {
  //   console.log('Google reCAPTCHA loaded and is ready for use!')
  // }
  //
  // onScriptError() {
  //   alert('Reload, something went long when loading the Google reCAPTCHA')
  // }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  decimalConversion(price, additionalCost?){
    return this.selectedCurrency + " " + this.currency + " " + this.accumulativePrice.transform([price, additionalCost]);
  }

  innerHtmlCount(description, longDescription){
    //let content = description.replace(/<(?:.|\n)*?>/gm, '') +  longDescription.replace(/<(?:.|\n)*?>/gm, '')
    let content = description +  longDescription;
    return content.length
  }

  getColor(color){
   return color;
  }

  checkIfTransactionExceedsMax(cart_value, product_price, lineItem?) {
    if(this.lineItem) {
      cart_value = parseFloat(cart_value)  - parseFloat(lineItem.attributes.price_values.amount)
    }
    if(parseFloat(cart_value) == 0 || (['v2', 'v3'].includes(window['data']['checkoutFlow']) && this.buyNowButton && this.totalItemInCard > 0)) {
      if(product_price > this.maxCartTransaction) {
        this.openTransactionExceededPopup();
        return true;
      }
      return false;
    } else {
      if(this.maxCartTransaction && (parseFloat(cart_value) + product_price) > this.maxCartTransaction) {
        this.openTransactionExceededPopup();
        return true;
      }
      return false;
    }
  }

  openTransactionExceededPopup() {
    this.maxTransactionDialogRef.close();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.currency + this.maxCartTransaction;
    this.maxTransactionDialogRef = this.dialog.open(MaxTransactionExceededComponent, dialogConfig);
  }

  openNoShipPopup(){
    this.noShipDialogRef.close();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.noShipDialogRef = this.dialog.open(NoShipLocationComponent, dialogConfig);
  }

  openOutOfStockPopup(){
    this.outOfStockDialogRef.close();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.body.classList.add("oos-pop-up-open");
    this.outOfStockDialogRef = this.dialog.open(OutOfStockComponent, dialogConfig);
    this.outOfStockDialogRef.afterClosed().subscribe(result => {
      this.body.classList.remove("oos-pop-up-open");
      console.log('Dialog closed');
    });
    setTimeout(()=>{
      this.outOfStockDialogRef.close();
    }, 5000);

  }

  mobCarouselVariantDotRemoval(){
    setTimeout(function () {
      const mobCarousel = document.querySelector(".product-carousel-mob") as HTMLElement;
      if(mobCarousel){
        const owlItems = mobCarousel.querySelectorAll(".owl-item") as NodeListOf<Element>;
        const owlDots = mobCarousel.querySelector(".owl-dots") as HTMLElement;
        const owl_dots_ind = mobCarousel.querySelectorAll(".owl-dot")as NodeListOf<Element>;
        const owlNext = mobCarousel.querySelector(".owl-next") as HTMLElement;
        const owlPrev = mobCarousel.querySelector(".owl-prev") as HTMLElement;

        owlItems.forEach((item, index) => {
          let initialX: number, currentX: number;

          // Function to handle touchstart event
          const onTouchStart = (event: TouchEvent) => {
            initialX = event.touches[0].clientX;
            let activeDot = owlDots.querySelectorAll('.var-active') as NodeListOf<HTMLElement>;
            activeDot.forEach(dot => {
              dot.classList.remove('active');
              dot.classList.remove('var-active');
            });
            item.addEventListener('touchmove', onTouchMove);
            item.addEventListener('touchend', onTouchEnd);
          };

          // Function to handle touchmove event
          const onTouchMove = (event: TouchEvent) => {
            // Prevent default touchmove behavior
            event.preventDefault();
            currentX = event.touches[0].clientX;
          };

          // Function to handle touchend event
          const onTouchEnd = (event: TouchEvent) => {
            // Do not prevent the default touchend behavior to allow click events
            const deltaX = currentX - initialX;
            let activeDot = owlDots.querySelectorAll('.var-active') as NodeListOf<HTMLElement>;
            activeDot.forEach(dot => {
              dot.classList.remove('active');
              dot.classList.remove('var-active');
            });
            console.log(deltaX);
            if (deltaX < -50) {
              //console.log('Drag left detected!');
            }
            // Remove event listeners for touchmove and touchend events
            item.removeEventListener('touchmove', onTouchMove);
            item.removeEventListener('touchend', onTouchEnd);
          };

          // Add an event listener for the touchstart event on each owl_item
          item.addEventListener('touchstart', (event: TouchEvent) => {
            onTouchStart(event);
          });
        });


        owlNext.addEventListener('click', () => {
          let activeDot = owlDots.querySelectorAll('.var-active') as NodeListOf<HTMLElement>;
          activeDot.forEach(dot => {
            dot.classList.remove('active');
            dot.classList.remove('var-active');
          });
        });

        owlPrev.addEventListener('click', () => {
          let activeDot = owlDots.querySelectorAll('.var-active') as NodeListOf<HTMLElement>;
          activeDot.forEach(dot => {
            dot.classList.remove('active');
            dot.classList.remove('var-active');
          });
        });
        // Loop through each owl_dot element and attach a click event listener.
        owl_dots_ind.forEach((dot: HTMLElement, index: number) => {
          dot.addEventListener("click", (event: Event) => handleDotClick(event, index));
        });

        // Define the function to be executed when a dot is clicked.
        function handleDotClick(event: Event, dotIndex: number): void {
          let activeDot = owlDots.querySelectorAll('.var-active') as NodeListOf<HTMLElement>;
          activeDot.forEach(dot => {
            dot.classList.remove('active');
            dot.classList.remove('var-active');
          });
          //console.log("Clicked dot index:", dotIndex);
        }
        $('.product-carousel-mob').trigger('stop.owl.autoplay');
      }
    }, 300);
  }

  bootstrapCarousel() {
      /*$('.product-carousel-mob').owlCarousel({
        loop: false,
        nav: true,
        dots: true,
        autoplay:false,
        autoplayTimeout:1000,
        autoplayHoverPause:false,
        stagePadding: 0,
        mouseDrag: true,
        responsiveClass:true,
        responsive: {
          0:{
            items: 1,
            margin: 20,
          },
          450:{
            items: 1,
            margin: 20,
          },
          768:{
            items: 1,
            margin: 52,
            stagePadding: 50
          }
        },
        navText: ["<",">"]

      });*/
      //console.log('test mob car')

    setTimeout(function () {
      /*$('.product-carousel-desktop').owlCarousel({
        loop: false,
        autoplay:false,
        autoplayTimeout:3000,
        nav: true,
        dots: false,
        autoplayHoverPause:true,
        stagePadding: 0,
        mouseDrag: true,
        responsiveClass:true,
        responsive: {
          0:{
            items: 0
          },
          450:{
            items: 0
          },
          768:{
            items: 0
          },
          1024:{
            items: 4,
            margin: 10,
            stagePadding: 0,
            nav: true
          }
        },
        navText: ["<",">"]

      });*/
      //console.log('test mob car')
    }, 6000);
  }

  brandfollowSubmmited = false;

  get f2() { return this.brandFollow.controls; }

  followBrand(){
    this.brandfollowSubmmited = true;
    if (this.brandFollow.invalid) {
    }else{
      this.followService.save(this.brandFollow.value).subscribe((data) => {
        this.product['attributes']['brand_follow_data']['brand_followed'] = true
        this.product['attributes']['brand_follow_data']['follow_request_approved'] = false
        CustomAlert.show('FOLLOW_REQUEST_TEXT');
      })
    }
  }

  BuyNow(){
    if((window['data']['checkoutFlow'] == "v2")){
      if((this.accessToken || this.cookiesResetService.get('guest_email'))){
        if(this.allDigital){
          window.location.href = this.subfoldingLinkService.linkWithSubdomainOrNot('/checkout');
        }else{
          window.location.href = this.subfoldingLinkService.linkWithSubdomainOrNot('/cart');
        }
      }else{
        this.buyNowLoginDialogRef.close();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.data = {allDigital: this.allDigital};
        this.buyNowLoginDialogRef = this.dialog.open(BuyNowLoginComponent, dialogConfig);
        this.buyNowButton = false;
      }
    }
  }

  buyNowV3(delivery_mode){
    if(this.cookiesResetService.get('guest_email')){
      if (this.totalItemInCard == 0) this.addLineItemToBasket(delivery_mode);
      else this.ConfirmForAlreadyCart(delivery_mode)
    }else{
      this.buyNowLoginV3DialogRef.close();
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = false;
      dialogConfig.data = {totalItemsInCart: this.totalItemInCard};
      this.buyNowLoginV3DialogRef = this.dialog.open(BuyNowLoginV3Component, dialogConfig);

      this.buyNowLoginV3DialogRef.afterClosed().subscribe(data => {
        if(data['continueToBuyNow']){
          this.addLineItemToBasket(delivery_mode);
        }else{
          this.disableAddToBasket = false;
          this.buyNowButton = false;
        }
      });
    }
  }

  ConfirmForAlreadyCart(delivery_mode){
    this.confirmBuyNowCartDialogRef.close();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    this.confirmBuyNowCartDialogRef = this.dialog.open(ConfirmBuyNowWithCartComponent, dialogConfig);

    this.confirmBuyNowCartDialogRef.afterClosed().subscribe(data => {
      if(data['continueToBuyNow']){
        this.addLineItemToBasket(delivery_mode);
      }else{
        this.disableAddToBasket = false;
        this.buyNowButton = false;
      }
    });
  }

  to_f(amount){
    return ((typeof(amount) === 'string') ? parseFloat(amount.replace(/\,/g,'')) : amount);
  }

  getSelectedVariant(){
    setTimeout(()=>{
      if(this.variantSelected || this.selectedVariant != null){
        if (this.selectedVariant['attributes']['images']['data']['0']){
          this.tabContent = this.selectedVariant['attributes']['images']['data']['0']['attributes']['styles'][4]['url'];
          this.altText = this.selectedVariant['attributes']['images']['data']['0']['attributes']['alt']
          var images = $(".prod-image");
          let mob_carousel = document.querySelector(".product-carousel-mob");
          let owl_items = mob_carousel.querySelectorAll(".owl-item");
          let owl_dots = mob_carousel.querySelectorAll(".owl-dot");
          let items_stages = [];
          let _sum = 0;
          owl_items.forEach((item, index)=>{
            //console.log(index, item)
            let itemStyle = getComputedStyle(item);
            let item_width = parseFloat(itemStyle.width);
            let item_margin = parseInt(itemStyle.marginRight);
            let item_stage;
            if (index == 0){
              items_stages.push(0);
            }
            else {
              items_stages.push(Math.ceil(item_width + item_margin));
            }
          });
          let sums = items_stages.map(value => _sum += value);
          //console.log(sums);

          owl_dots.forEach((item, index)=>{
            item.setAttribute('id','dot-btn-'+index)
          });

          for(var i = 0; i < images.length; i++){
            var url = images[i].src.replace(/^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//, '/');
            if(this.tabContent === url){
              $(".owl-item").removeClass("active");
              images[i].closest(".owl-item").classList.add("active");
              $('.product-carousel-mob .owl-stage').css('transform','translate3d(-'+sums[i]+'px, 0px, 0px)');
              $('.product-carousel-mob .owl-stage').css('transition','all 0.25s ease 0s');
              $('.product-carousel-mob .owl-dots .owl-dot').removeClass('active');
              $('.product-carousel-mob .owl-dots .owl-dot').removeClass('var-active');
              $('.product-carousel-mob .owl-dots .owl-dot#dot-btn-'+[i]).addClass('active var-active');
              if (i != 0) {
                $('.product-carousel-mob.tab .owl-nav .owl-prev').removeClass('disabled');
              }
              console.log(sums[i]);
              break;
            }
          }
        }
      }
    }, 1500);
  }
  updateRecipientPhoneValidation(event){
    if(event.target.checked){
      if(this.product.attributes.send_gift_card_via == "both" || this.product.attributes.send_gift_card_via == "sms"){
        this.personalization.controls.receipient_phone_number.setValidators([Validators.required, Validators.pattern('[- +()0-9]+')]);
      }
      this.sendViaSmsSelected = true;
    }else{
      this.sendViaSmsSelected = false;
      this.personalization.controls.receipient_phone_number.setValue("");
      this.personalization.controls.receipient_phone_number.clearValidators();
    }
    this.personalization.controls.receipient_phone_number.updateValueAndValidity();
  }

  onVerify(event: any) {
    this.contactSeller.patchValue({ myRecaptcha: event });
  }

  onExpired($event: any) {
    this.contactSeller.patchValue({ myRecaptcha: null });
  }

  onError($event: any) {
    this.contactSeller.patchValue({ myRecaptcha: null });
  }
}
