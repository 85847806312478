import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SubscriptionService } from "../../services/subscription.service";
import { HtmlComponentService } from "../../services/html_component.service";
import templateString from './newsletter.component.html';
import styleString from './newsletter.component.scss';
import CustomAlert from '../../../app/models/custom-alert.model';

@Component({
  selector: 'app-newsletter',
  template: templateString,
  styles: [ styleString ],
  providers: [ SubscriptionService, HtmlComponentService ]
})
export class NewsletterComponent implements OnInit {
  newsLetterForm: FormGroup;
  submitted = false;
  subscriptionTitle = "BE IN THE KNOW";
  subscriptionText = "Sign up for our newsletters to&nbsp;receive exclusive promos, gift guides and much more!";
  newLetter;

  constructor(
    private formBuilder: FormBuilder,
    private subscriptionService: SubscriptionService,
    private htmlComponentService: HtmlComponentService
  ) {}

  ngOnInit() {
    if(window["data"]["subscriptionTitle"]) this.subscriptionTitle = window["data"]["subscriptionTitle"];
    if(window["data"]["subscriptionText"]) this.subscriptionText = window["data"]["subscriptionText"];

    this.newsLetterForm = this.formBuilder.group({ email: ['', [ Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/)]] });
    this.htmlComponentService.getNewLetterData().subscribe(data => {
      this.newLetter = data["data"];
      if(this.newLetter) this.newLetter = this.newLetter.attributes;
    });
  }

  getStyles(newLetter){
    if(!newLetter) return "";
    return {
              "background-color": newLetter.background_color,
              "text-align": newLetter.text_allignment,
              "color": newLetter.font_color
            }
  }

  onSubmit(){
    this.submitted = true;
    if (this.newsLetterForm.invalid) return;
    else{
      let email = this.newsLetterForm.controls.email.value;
      this.subscriptionService.subscribeMailchimp(email, false, true).subscribe((subscription) => {
        subscription = subscription['body'];
        CustomAlert.show('SUBSCRIPTION_MESSAGE',{msg: subscription['message']});
      }, (err) => { 
        CustomAlert.show('ERROR',{ error: `error:  ${err['statusText']}` });
      });
    }
  }
}
