import { Component, OnInit, Input, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup  } from '@angular/forms';
import { SessionService } from '../../../services/session.service'
import { UserService } from '../../../services/user.service'
import { WishedProductService } from '../../../services/wished-product.service'
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import templateString from './login-cart.component.html';
import styleString from './login-cart.component.scss';
import { SubfoldingLinkService } from '../../../services/subfolding-link.service';
import { CookiesResetService } from '../../../services/cookies-reset.service';
import CustomAlert from '../../../models/custom-alert.model';

@Component({
  selector: 'app-login-cart',
  template: templateString,
  styles: [ styleString ],
  providers:  [ SessionService, UserService, WishedProductService, SubfoldingLinkService ]
})
export class LoginCartComponent implements OnInit {
  login: FormGroup;
  submitted:boolean

  constructor(public window: Window,
              public sessionService: SessionService,
              public userService: UserService,
              public wishedProductService: WishedProductService,
              public formBuilder: FormBuilder,
              public cookieService: CookieService,
              public router: Router,
    public subfoldingLinkService: SubfoldingLinkService,
    public cookiesResetService: CookiesResetService
  ) { }

  ngOnInit() {
    this.login = this.formBuilder.group({
      email:['', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/)]],
      password:['',Validators.required],
    });
  }

  get f() { return this.login.controls; }

  onSubmit() {
    var email = (this.login.get('email').value).toLowerCase();
    var password = this.login.get('password').value
    this.submitted = true;
    // stop here if form is invalid
    if (this.login.invalid) {
      return;
    }else{
      this.sessionService.obtainAccessToken({username: email, password: password}).subscribe((data: any) => {
        this.sessionService.deleteGuestEmail();
        var tokenData = data;
        if(tokenData.roles == "customer"){
          const onLoginOkBtnClicked = (self) => {
            self.sessionService.saveToken(tokenData);
            self.userService.mergeCart().subscribe((token: any) => {
              var orderToken = token["token"];
              // self.cookiesResetService.setItem('order_token', JSON.stringify(orderToken))
              self.cookiesResetService.set("order_token", orderToken, null)
            })
            setTimeout(() => { window.location.href = self.subfoldingLinkService.linkWithSubdomainOrNot('/cart') }, 2000);
          }
          CustomAlert.confirm('LOGIN',{},{
            showYesActionBtn:true,
            yesActionText: 'OK',
            yesActionCallback: onLoginOkBtnClicked,
            self: this
          });
        }else{
          CustomAlert.show('CUSTOMER_IS_NOT_AUTHORIZED');
        }
      },
      err => {
        CustomAlert.show('INCORRECT_USERNAME_OR_PASSWORD');
      }
    );
    }
  }
}
