import { Component, OnInit, Inject, Input, ElementRef, Renderer2, HostListener, ViewChild} from '@angular/core';
import templateString from './iframe.component.html';
import styleString from './iframe.component.scss';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CartService } from 'storefront/app/services/cart.service';
import { CookiesResetService } from 'storefront/app/services/cookies-reset.service';
import { SubfoldingLinkService } from 'storefront/app/services/subfolding-link.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpErrorResponse } from '@angular/common/http';
import CustomAlert from '../../../app/models/custom-alert.model';
import { AccumulativePricePipe } from '../../pipes/accumulative-price.pipe';
import { Location } from '@angular/common';
import * as moment from "moment-timezone";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';


declare global {
  interface Window {}
}
@Component({
  selector: 'app-iframe',
  template: templateString,
  styles: [styleString],
  providers: [AccumulativePricePipe]
})

export class IframeComponent {
  @Input() product: any;
  @ViewChild('picker') dateTimePicker: NgxMatDatetimePicker<any>;

  constructor(
    public window: Window,
    private cartService: CartService,
    private formBuilder: FormBuilder,
    private cookiesResetService: CookiesResetService,
    private spinnerService: Ng4LoadingSpinnerService,
    private subfoldingLinkService: SubfoldingLinkService,
    private location: Location,
    private accumulativePrice: AccumulativePricePipe,
    private el: ElementRef, private renderer: Renderer2
  ) {}

  iframeProductFrom: FormGroup;
  submitted: boolean;
  buyGiftCard: boolean = false;
  editMode: boolean = false;
  recipientType: string = '';
  customOptionId: string = '';
  giftCardLimit = {};
  order: any;
  selected_line_item: any;
  viewableImages: any = [];
  selectedCurrency: string = "USD";
  currency: string;
  disableSubmitButton: boolean = false;
  minDate: Date;
  selectedImage: any;

  customOptions: OwlOptions = {
    loop: false,
    autoplay:false,
    autoplayTimeout:3000,
    nav: true,
    dots: false,
    autoplayHoverPause:true,
    stagePadding: 0,
    mouseDrag: true,
    //responsiveClass:true,
    responsive: {
      0:{
        items: 0
      },
      450:{
        items: 4,
        margin: 10,
        stagePadding: 0,
        nav: true
      }
    },
    navText: ["<",">"]
  };


  ngOnInit() {
    this.iframeProductFrom = this.formBuilder.group({
      quantity: [1, [Validators.required, Validators.min(1)]],
      vendor_id: [],
      user_gift_amount: [0],
      recipient_name: ['', Validators.required],
      recipient_email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/)]],
      gift_card_message: [''],
      sender_name: [''],
      sender_email: [""],
      shipment_type: ['shipment_default', Validators.required],
      card_generation_datetime: [''],
      variant_id: [null],
      choose_gift_card: ['no']
    });
    this.product.attributes.customizations.data.forEach(customization => {
      // Replace the existing customization options with sorted ones
      customization.attributes.customization_options = customization.attributes.customization_options.sort(
          (a, b) => Number(a.customizable) - Number(b.customizable)
      );
    });
    
    this.selectedImage = this.product.attributes.variant_images.data[0];
    this.minDate = new Date();
    this.giftCardLimit['min'] = this.product.attributes.price_values.custom_price_range.min;
    this.giftCardLimit['max'] = this.product.attributes.price_values.custom_price_range.max;
    this.currency = this.cookiesResetService.get('curencySymbol');
    this.selectedCurrency = this.cookiesResetService.get('curency');

    this.resetGiftAmount();
    this.initProductImages();
    this.navBarSteps();

    this.getCart( () => {
      this.initProductImages();
      if(this.editMode && this.viewableImages && this.recipientType == "gifting")
        this.iframeProductFrom.patchValue({ choose_gift_card: this.cookiesResetService.get('iframe_choose_gift_card') != '' ? this.cookiesResetService.get('iframe_choose_gift_card') : 'no' });
    });
  }

  onSubmit() {
    const maxQuantity = this.product.attributes.preferences.maximum_order_quantity;

    if (maxQuantity && this.iframeProductFrom.get('quantity').value > maxQuantity) {
      CustomAlert.show('MAXIMUM_ORDER_QUANTITY', { max_quantity: maxQuantity });
      this.submitted = true;
      return; 
    }
    if (this.iframeProductFrom.invalid) {
      this.submitted = true;
      return;
    } else {
      this.spinnerService.show();
      this.submitted = false;
      const formValue = this.iframeProductFrom.value;
      const formData = this.buildFormData(formValue);
      this.disableSubmitButton = true;
      this.cartService.createIframeCart(formData).subscribe((data) => {
        this.spinnerService.hide();
        this.setTokens(data['data']['attributes']);
        this.setGuestEmail();
        window.location.href = this.subfoldingLinkService.linkWithSubdomainOrNot('/checkout');
      },(err:HttpErrorResponse)=>{
        let error = err["_body"];
        if(error) error = JSON.parse(error);
        else error = err.error;
          CustomAlert.show('ERROR',{ error: error.error });
          this.disableSubmitButton = false;
      });
    }
  }

  navBarSteps(event?: any) {
    if(event){
      const clickedElement = event.currentTarget as HTMLElement;
      const dataValue = clickedElement.getAttribute('data-value');
      if (dataValue == "one"){
        const breadCrumpStepOne = this.el.nativeElement.querySelector('#step-one');
        const breadCrumpStepTwo = this.el.nativeElement.querySelector('#step-two');
        breadCrumpStepOne.classList.remove('processed');
        breadCrumpStepTwo.classList.remove('processing');
        breadCrumpStepOne.classList.add('processing');
        this.buyGiftCard = false;
      }
    }
  }

  iframeUrlChange(event) {
    window.location.href = event.target.value;
  }

  selectOption(id, type) {
    // Update Validations for customize gift card
    if(type == "customizeInput"){
      this.updateValidations();
      if (this.iframeProductFrom.controls.user_gift_amount.invalid) return;
    }
    else{
      this.resetGiftAmount();
    }
    this.buyGiftCard = true;
    this.customOptionId = id;
    this.UpdateValidationsByRecipientType();
    this.stepTwoBreadCrumpClasses();
  }

  stepTwoBreadCrumpClasses(){
    if(this.buyGiftCard){
      // add classes in breadCrump after click on buy button
      const breadCrumpStepOne = this.el.nativeElement.querySelector('#step-one');
      const breadCrumpStepTwo = this.el.nativeElement.querySelector('#step-two');
      breadCrumpStepOne.classList.remove('processing');
      breadCrumpStepOne.classList.add('processed');
      breadCrumpStepTwo.classList.add('processing');
    }
  }

  initProductImages(){
    let productImages = this.product['attributes']['variant_images']['data'];
    const seen = new Set<number>();
    this.viewableImages = productImages.filter((obj) => {
      const viewableId = obj.attributes.viewable_id;
      if (!seen.has(viewableId)) { seen.add(viewableId);
        return true;
      }
      return false;
    });
    if (this.editMode && this.viewableImages)
      this.selectedImage = this.viewableImages.find( (image) => image.attributes.viewable_id == this.selected_line_item.variant_id );
  }

  onRecipientTypeChange(event: Event): void {
    this.recipientType = (event.target as HTMLInputElement).value;
    this.cookiesResetService.set('iframe_gift_recipient_type', this.recipientType);
    this.UpdateValidationsByRecipientType();
    this.resetFormValues();
    this.resetVariantImages();
  }

  onChooseGiftCard(event: Event): void {
    let chooseGiftCard = (event.target as HTMLInputElement).value;
    this.cookiesResetService.set('iframe_choose_gift_card', chooseGiftCard);
    if(chooseGiftCard == "no") this.resetVariantImages();
  }

  selectVariant(variantId) {
    this.selectedImage = this.viewableImages.filter(a => a.attributes.viewable_id === variantId)[0];
    this.iframeProductFrom.patchValue({ variant_id: variantId });
  }

  setTokens(cart) {
    this.cookiesResetService.set('order_token', cart['token']);
    this.cookiesResetService.setSessionItem('cart_token', JSON.stringify(cart["cart_token"]));
  }

  buildFormData(formValue) {
    let variants = [];

    if(this.product.attributes.variants.data.length>0){
      variants = this.product.attributes.variants.data.filter(variant => variant.attributes.archived == 'false');
    }

    const variant_id = variants.length ? variants[0].id : this.product.attributes.master_variant;
    return {
      variants: [
        {
          id: formValue.variant_id ?? variant_id,
          quantity: formValue.quantity,
          options: {
            glo_api: true,
            vendor_id: this.product.attributes.vendor_id,
            customization_options: [
              formValue.user_gift_amount !== "" ?
                { customization_option_id: this.customOptionId, gift: { user_gift_amount: formValue.user_gift_amount } } : { customization_option_id: this.customOptionId, value: null }
            ],
            receipient_email: formValue.recipient_email,
            receipient_first_name: formValue.recipient_name,
            message: formValue.gift_card_message,
            sender_name: formValue.sender_name,
            shipment_type: formValue.shipment_type,
            card_generation_datetime: moment.utc(Date.parse(formValue.card_generation_datetime)).format('YYYY-MM-DD hh A'),
            card_generation_timezone: moment.tz.guess()
          },
        },
      ],
      email: (formValue.sender_email == "")? formValue.recipient_email : formValue.sender_email,
      buy_now_button: false,
      cart_changed: true
    }

  }

  setGuestEmail() {
   let email = (this.recipientType == "gifting") ? this.iframeProductFrom.controls.sender_email.value : this.iframeProductFrom.controls.recipient_email.value
   this.cookiesResetService.set('guest_email', email);
  }

  updateValidations() {
    this.iframeProductFrom.controls['user_gift_amount'].setValidators([
      Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.min(this.giftCardLimit["min"]),
      Validators.max(this.giftCardLimit["max"])
      ]);
      this.iframeProductFrom.controls['user_gift_amount'].updateValueAndValidity();
    }

  resetGiftAmount() {
    this.iframeProductFrom.controls['user_gift_amount'].setValue("");
    this.iframeProductFrom.controls['user_gift_amount'].clearValidators();
    this.iframeProductFrom.controls['user_gift_amount'].updateValueAndValidity();
  }

  resetFormValues(){
    this.iframeProductFrom.controls['recipient_name'].setValue("");
    this.iframeProductFrom.controls['recipient_email'].setValue("");
    this.iframeProductFrom.controls['gift_card_message'].setValue("");
    this.iframeProductFrom.controls['sender_name'].setValue("");
    this.iframeProductFrom.controls['sender_email'].setValue("");
    this.iframeProductFrom.controls['card_generation_datetime'].setValue("");
    this.iframeProductFrom.controls['shipment_type'].setValue('shipment_default');
    this.iframeProductFrom.controls['choose_gift_card'].setValue('no');
  }

  resetVariantImages(){
    this.iframeProductFrom.patchValue({ variant_id: null });
    this.selectedImage = this.product.attributes.variant_images.data[0];
  }

  UpdateValidationsByRecipientType(){
    const controls = this.iframeProductFrom.controls;
    if(this.recipientType=="gifting"){
      controls['gift_card_message'].setValidators([Validators.required]);
      controls['sender_name'].setValidators([Validators.required]);
      controls['sender_email'].setValidators( [Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/)]);
      controls['shipment_type'].setValidators([Validators.required]);
      controls['choose_gift_card'].setValidators([Validators.required]);
    }else{
      controls['gift_card_message'].setValue("");
      controls['gift_card_message'].clearValidators();

      controls['sender_name'].setValue("");
      controls['sender_name'].clearValidators();

      controls['sender_email'].setValue("");
      controls['sender_email'].clearValidators();

      controls['shipment_type'].setValue('shipment_default');
      controls['shipment_type'].clearValidators();

      controls['choose_gift_card'].setValue('no');
      controls['choose_gift_card'].clearValidators();

      controls['card_generation_datetime'].setValue("");
      controls['card_generation_datetime'].clearValidators();
    }

    controls['gift_card_message'].updateValueAndValidity();
    controls['sender_name'].updateValueAndValidity();
    controls['sender_email'].updateValueAndValidity();
    controls['shipment_type'].updateValueAndValidity();
    controls['choose_gift_card'].updateValueAndValidity();
    controls['card_generation_datetime'].updateValueAndValidity();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getCart(callback?:Function){
    if(this.cookiesResetService.get('access_token') || this.cookiesResetService.get('order_token')) {
      this.cartService.getCart().subscribe((data) => {
        this.order = data["data"]["attributes"];
        this.selected_line_item = this.order?.line_items_with_types[0][Object.keys(this.order.line_items_with_types[0])[0]].data[0].attributes;
        if(this.selected_line_item.slug != this.product.attributes.slug) return
        this.recipientType = this.cookiesResetService.get('iframe_gift_recipient_type');
        this.populateFormData(this.order);
        this.editMode = true;
        this.UpdateValidationsByRecipientType();
        const stepValue = new URLSearchParams(this.location.path().split('?')[1]).get('step');
        this.buyGiftCard = (stepValue == "one")? false : true;
        this.stepTwoBreadCrumpClasses();
        callback.call(this);
      },(err:HttpErrorResponse) => {
          let error = err["_body"];
          if(error) error = JSON.parse(error);
          else error = err.error;
          CustomAlert.show('ERROR',{ error: error.error});
      });
    }
  }

  populateFormData(order) {
    let customizationData = this.selected_line_item.line_item_customizations.data;
    let gift_amount = 0;
    if(customizationData.length > 0){
      this.customOptionId = customizationData[0].attributes.customization_option_id;
      if(customizationData[0].attributes.customizable)
        gift_amount = parseInt(this.selected_line_item.custom_price);
      }
    this.iframeProductFrom.patchValue({
      quantity: this.selected_line_item.quantity,
      recipient_name: this.selected_line_item.receipient_first_name,
      recipient_email: this.selected_line_item.receipient_email,
      gift_card_message: this.selected_line_item.message,
      sender_name: this.selected_line_item.sender_name,
      variant_id: this.selected_line_item.variant_id,
      vendor_id: this.selected_line_item.vendor_id,
      sender_email: order.email,
      user_gift_amount: gift_amount,
      shipment_type: order.iframe_shipment_type,
      card_generation_datetime: order.iframe_card_generation_datetime
    });
  }

  @HostListener('document:click', ['$event'])
  closeCalendar(event: Event): void {
    if (this.dateTimePicker && (event.target as Element).className.split('-')[0] != 'mat')
      this.dateTimePicker.close();
  }
  onScheduleGiftCard(value){
    const controls = this.iframeProductFrom.controls;
    (value) ? controls['card_generation_datetime'].setValidators([Validators.required]) :
    controls['card_generation_datetime'].clearValidators();
    controls['card_generation_datetime'].updateValueAndValidity();
  }
}