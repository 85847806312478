import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { VendorService } from '../services/vendor.service'
import { ProductService } from '../services/product.service'
import { CookieService } from 'ngx-cookie-service';
import templateString from './vendor.component.html';
import styleString from './vendor.component.scss';
import { DomSanitizer } from '@angular/platform-browser'
import {BlogsService} from "storefront/app/services/blogs.service";
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { ConfigurationService } from '../services/configuration.service';
import { CountryService } from '../services/country.service';
import { FollowService } from '../services/follow.service';
import { SessionService } from '../services/session.service';
import { SubfoldingLinkService } from '../services/subfolding-link.service';
import { CookiesResetService } from '../services/cookies-reset.service';
import CustomAlert from '../../app/models/custom-alert.model';

@Component({
  selector: 'app-vendor',
  template: templateString,
  styles: [ styleString ],
  providers:  [ VendorService,ProductService, ConfigurationService, CountryService, FollowService, SessionService, SubfoldingLinkService ]
})
export class VendorComponent implements OnInit {

  vendorId = null;
  vendor:any;
  logoImage = null;
  vendorProducts:any;
  screenWidth;
  no_of_prods = 0;
  vendorShippingAddress;
  bannerImage = "http://media.graphcms.com/9hMmqWhcQ3mDYF0d0QI3"
  vendorNameparameterized = "";
  description: any;
  contactSeller: FormGroup;
  askSellerSubmitted:boolean;
  question_id = '';
  answer = '';
  question = '';
  product_id;
  questionable_id = null;
  countries =[];
  brandFollow: FormGroup;
  accessToken='';
  // vendorVisibility;
  // isSelectedStore;
  selectedCurrency;
  currency;

  constructor(private window: Window, private router: Router,
    private spinnerService: Ng4LoadingSpinnerService,
    private vendorService: VendorService,
    private productService: ProductService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private countryService: CountryService,
    public followService: FollowService,
    public sessionService: SessionService,
    public subfoldingLinkService: SubfoldingLinkService,
    public cookiesResetService: CookiesResetService
    ) {
    this.accessToken = this.cookiesResetService.get("access_token");
    this.route.paramMap.subscribe((params) => {
     this.vendorId = params.get('id');
    })
    if(this.window.screen){
      this.screenWidth = this.window.screen.width;
    }
    if (this.screenWidth > 769) {
      this.no_of_prods = 5;
    }
    else {
      this.no_of_prods = 4;
    }

    // this.isSelectedStore = this.productService.isSelectedStore();

    // if(this.window["data"])
    //   this.vendorVisibility = this.window["data"]["storeItemVisibility"]["vendorVisibility"];
  }

  fetchAllCountries(){
    this.countryService.removeCountriesWithStoreName().subscribe(
        (countries) => { this.countries = countries["data"]; }
    );
  }

  ngOnInit() {
    this.fetchAllCountries();
    this.getVendorData();
    this.getVendorProducts();
    this.currency = this.cookiesResetService.get('curencySymbol')
    this.selectedCurrency = this.cookiesResetService.get("curency")
    this.contactSeller = this.buildContactSellerForm();
    this.route.queryParamMap.subscribe((params) => {
      if(params.has("question_id")) {
        this.question_id = params.get("question_id");
        this.getQuestionData(this.question_id);
      }
      if(params.has("order_id")){
        console.log("Order Present");
        this.questionable_id = params.get("order_id");
        this.contactSeller.patchValue({
          questionable_id: this.questionable_id,
          questionable_type: "Spree::Order",
        });
      }
    });
    //Brand Follow Form

    this.brandFollow = this.formBuilder.group({
      follower_id:[],
      name:['',[Validators.required]],
      website:['', [Validators.required]],
      instagram: '',
      country_name: ['', [Validators.required]],
      details:['', [Validators.required]]
    });
  }

  brandfollowSubmmited = false;

  get f2() { return this.brandFollow.controls; }

  followBrand(){
    this.brandfollowSubmmited = true;
    if (this.brandFollow.invalid) {
      console.log("here")
    }else{
      console.log(this.brandFollow.value)
      this.followService.save(this.brandFollow.value).subscribe((data) => {
        this.vendor['attributes']['brandFollowData']['brand_followed'] = true
        this.vendor['attributes']['brandFollowData']['follow_request_approved'] = false
        console.log(data)
        CustomAlert.show('FOLLOW_REQUEST_TEXT');
      })
    }
  }

  getQuestionData(id) {
    this.configurationService.getQuestion(id).subscribe((data) => {
      const question = data["data"]["attributes"]
      this.contactSeller.patchValue({
          guest_name: question["guest_name"],
          guest_email: question["guest_email"],
        });
      this.question = question["title"]
      this.answer = question["answer"]

      this.productService.show(question.product_attributes.product_sku).subscribe((data) => {
        this.product_id = data["data"]["id"];
      });
    });
  }

  getVendorData(){
    this.spinnerService.show();
    this.vendorService.show(this.vendorId).subscribe(
      (vendor) => {
        this.vendor = vendor["data"];
        this.brandFollow.patchValue({follower_id: this.vendor['attributes']['brandFollowData']['vendor_user_id']});
        this.description = this.sanitizer.bypassSecurityTrustHtml(this.vendor.attributes.description);
        this.vendorNameparameterized = this.vendor.attributes.parameterizeName;
        if(this.vendor.attributes.bannerImage){
          this.bannerImage = this.vendor.attributes.bannerImage;
        }
        if(this.vendor.attributes.image["data"]){
          this.logoImage = this.vendor.attributes.image.data.attributes.styles[2].url;
        }
        if(this.vendor.attributes.shippingAddress["data"]){
          this.vendorShippingAddress = this.vendor.attributes.shippingAddress["data"];
        }
        this.spinnerService.hide();
      });
  }


  getVendorProducts(){
    this.spinnerService.show();
    this.productService.findAllProducts({get_featured: true, vendor_landing_page_url_eq: this.vendorId}).subscribe(
      (data) => {
        this.vendorProducts = data["data"];
        this.spinnerService.hide();
      });
  }

  buildContactSellerForm(){
    let sellerForm = this.formBuilder.group({
      guest_name: ['', Validators.required],
      guest_email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/)]],
      title: ['', Validators.required],
      vendor_id: ['', Validators.required],
      questionable_id: [''],
      questionable_type: [''],
      product_id: [''],
      myRecaptcha: [null, Validators.required]
    });
    return sellerForm;
  }

  get f() { return this.contactSeller.controls; }

  askSeller() {
    this.askSellerSubmitted = true;

    this.contactSeller.patchValue({
      vendor_id: this.vendor["id"],
      product_id: this.product_id
    });

    if (this.contactSeller.invalid){
      CustomAlert.show('FILL_ALL_FIELDS');
      return;
    }else{
      const fd = new FormData();
      fd.append("question", JSON.stringify(this.contactSeller.value));
      this.configurationService.askSeller(fd).subscribe((data) => {
        CustomAlert.show('THANKS_FOR_QUESTION_TEXT');
        this.contactSeller = this.buildContactSellerForm();
        this.askSellerSubmitted = false;
      });
    }
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  onVerify(event: any) {
    this.contactSeller.patchValue({ myRecaptcha: event });
  }

  onExpired($event: any) {
    this.contactSeller.patchValue({ myRecaptcha: null });
  }

  onError($event: any) {
    this.contactSeller.patchValue({ myRecaptcha: null });
  }

}
